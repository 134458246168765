import * as React from 'react'
import * as contentful from 'contentful'

import { ArBlogPost } from 'src/components/ArBlogPost'
import { Helmet } from 'react-helmet'
import { PageProps } from 'gatsby'

const PostPreviewPage = (props: PageProps) => {
  const [post, setPost] = React.useState<contentful.Entry<unknown>>()

  const fetchPost = React.useCallback(async () => {
    const client = contentful.createClient({
      space: 'kbmaq5wq8te5',
      accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN as string,
      host: 'preview.contentful.com',
    })

    const searchParams = new URLSearchParams(props.location.search)
    const id = searchParams.get('id')
    if (typeof id === 'string') {
      const data = await client.getEntry(id)
      setPost(data)
    }
  }, [props.location.search])

  React.useEffect(() => {
    fetchPost()
  }, [fetchPost])

  return post ? (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div
        style={{
          position: 'sticky',
          top: '66px',
          width: '100%',
          height: '30px',
          backgroundColor: 'rgba(248, 113, 113, 0.3)',
          color: 'rgba(248, 113, 113, 0.9)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
        This is a content preview page. It is not indexed by search engines. Don't share this link with anyone.
      </div>
      <ArBlogPost data={{ post: post.fields }} isPreview />
    </>
  ) : (
    'Loading...'
  )
}

export default PostPreviewPage
